import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./menu.module.css";
import logo from "../../images/logo.png";

const Menu = () => {
  const nav = useNavigate();
  const homeHandler = () => {
    nav("/");
  };
  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <img src={logo} alt="MNAG Logo" onClick={homeHandler} />
      </div>
      <div>
        <nav className={classes.nav}>
          <ul>
            <li>
              <NavLink to="/" end>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/monday-meetings">Monday Meetings</NavLink>
            </li>
            <li>
              <NavLink to="/find-us">Find Us</NavLink>
            </li>
            <li>
              <NavLink to="/outreach">Outreach</NavLink>
            </li>
            <li>
              <NavLink to="/membership">Membership</NavLink>
            </li>
            <li>
              <NavLink to="/Gallery">Gallery</NavLink>
            </li>
            <li>
              <NavLink to="/Calendar">Calendar</NavLink>
            </li>
            <li>
              <NavLink to="/affiliates">Affiliates</NavLink>
            </li>
            <li>
              <NavLink to="/newsletter">Newsletters</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
