import React from "react";
import classes from "./layout.module.css";
import Menu from "./menu";

export const Layout: React.FC = (props) => {
  return (
    <React.Fragment>
      <Menu />
      <div className={classes.main}>{props.children}</div>
      <div className={classes.footer}>
        <div>
          <a
            href="https://www.facebook.com/groups/1134365606653272/"
            target="_blank"
            title="Facebook"
            rel="noreferrer"
          >
            <img src="/images/facebook.jpg" alt="Facebook" />
          </a>
        </div>
        <p>&copy; {new Date().getFullYear()} - Marin Needle Arts Guild</p>
      </div>
    </React.Fragment>
  );
};
