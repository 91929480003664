import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/nav/layout";
import LoadingSpinner from "./components/UI/loadingspinner";

const Home = React.lazy(() => import("./components/pages/home"));
const ContactUs = React.lazy(() => import("./components/pages/contactus"));
const MondayMeetings = React.lazy(
  () => import("./components/pages/mondaymeetings")
);
const FindUs = React.lazy(() => import("./components/pages/findus"));
const Outreach = React.lazy(() => import("./components/pages/outreach"));
const Membership = React.lazy(() => import("./components/pages/membership"));
const Gallery = React.lazy(() => import("./components/pages/gallery"));
const Calendar = React.lazy(() => import("./components/pages/calendar"));
const Affiliates = React.lazy(() => import("./components/pages/affiliates"));
const Newsletter = React.lazy(() => import("./components/pages/newsletter"));
const NotFound = React.lazy(() => import("./components/pages/notfound"));
function App() {
  return (
    <div className="App">
      <Layout>
        <React.Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/monday-meetings" element={<MondayMeetings />}></Route>
            <Route path="/find-us" element={<FindUs />}></Route>
            <Route path="/outreach" element={<Outreach />}></Route>
            <Route path="/membership" element={<Membership />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/calendar" element={<Calendar />}></Route>
            <Route path="/affiliates" element={<Affiliates />}></Route>
            <Route path="/newsletter" element={<Newsletter />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </React.Suspense>
      </Layout>
    </div>
  );
}

export default App;
